<template>
  <div class="fill-height">
    <IproMain
        jenis="IPRO"
    ></IproMain>
  </div>
</template>

<script>
import IproMain from "../../components/public_potensi_kawasan/PotensiMain";

export default {
  name: "PotensiPeluangView",
  components:{
    IproMain
  },
  mounted() {
    document.title = "POINT JATIM | IPRO"
    this.$store.dispatch("potensi/updateShowMenuBar", true)
  }
}
</script>

<style scoped>

</style>